<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'

const isMobile = useIsMobile()
const router = useRouter()
const route = useRoute()

const loading = ref(true)
const source = ref(0)
const isPaidUser = ref(true)
const carriers = ref('Humana and UnitedHealthcare®')
const show = ref(false)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (
    brand.id !== 'bridge' &&
    import.meta.env.MODE === 'production' &&
    (!isMobile.value || !isPaidUser.value)
  ) {
    router.push('/go-4-benefits')
    return
  }

  enterExperiment('getYoursSSM1Redirect')

  if (exp.value?.getYoursSSM1Redirect?.redirect) {
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    const qs = params.toString() && `?${params.toString()}`

    router.replace('/get-yours-ss1-m' + qs)

    return
  }

  show.value = true
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })

  const state = states.find((st) => st.code === ip.lookup?.stateCode)

  const zip = route.query.zip_code
    ? Array.isArray(route.query.zip_code)
      ? (route.query.zip_code.pop() as string)
      : (route.query.zip_code as string)
    : ip.lookup?.postalCode || ''

  let benefitSummary

  if (zip) {
    try {
      benefitSummary = await getGivebackSummaryV2({
        zip_code: zip,
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (benefitSummary) {
    carriers.value = joinWithAnd(benefitSummary.matching_carriers)
  }

  const minGiveback = benefitSummary?.min_giveback
    ? Number(
        benefitSummary.benefit_period === 'per month'
          ? benefitSummary.min_giveback * 12
          : benefitSummary.min_giveback
      )
    : 0

  source.value = 0

  setTimeout(() => {
    loading.value = false

    source.value = Math.min(
      minGiveback ||
        Number(state?.maxFipsMinGivebackLtdAmount) ||
        state?.minGiveback ||
        500,
      2096
    )
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const destination = computed(() => {
  let domain = ''

  let path =
    isWorkingHours.value && isMobile.value && isPaidUser.value
      ? '/additional-benefits-buyback/call'
      : '/ads'

  const params = new URLSearchParams()

  params.set('black', 'true')

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    domain = 'https://benefit-helpline.com'

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)
  }

  const qs = params.toString() && `?${params.toString()}`

  return domain + path + qs
})

analytics.page({
  name: 'Get Your Social Security ss1',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout
    v-if="show"
    :footer="brand.id === 'bridge' ? 'ss1' : '2025'"
    :hide-header="true"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <template v-if="brand.id !== 'bridge' || (isMobile && isPaidUser)">
      <div
        class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
      >
        <span
          class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
        >
          Ad
        </span>

        <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
          <div
            class="text-white text-4xl md:text-5xl flex flex-col md:inline-block md:py-3 md:text-center"
          >
            Don't Wait, Find
            <span class="text-6xl md:text-7xl text-yellow">
              {{ USDollarFormatter(0).format(output) }}/yr+
            </span>
            or more In Your Social Security Check
          </div>

          <div
            class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
          >
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'no',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                No
              </span>
            </Button>
            <Button
              variant="undefined"
              :to="destination"
              :class="[
                'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
                'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
                'transition-all duration-150 ',
              ]"
              @click="
                analytics.track('learnMoreClicked', {
                  source: $route.fullPath,
                  destination,
                  button: 'yes',
                })
              "
            >
              <span
                class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
              >
                Yes
              </span>
            </Button>
          </div>

          <img
            src="../../assets/retirement-balance.png"
            alt="Two retirement balance, the left one with 'Before' and a list of $0 deposits and the second one with 'After' with several deposits of $174"
          />
        </div>
      </div>
      <div class="bg-black text-white">
        <div class="container space-y-2 py-2">
          <div class="text-sm text-center">
            New to Medicare? Discover a Medicare Advantage plan from well known
            carriers like {{ carriers }}
          </div>
          <div class="text-center text-gray-400">
            MULTI-PLAN_HLDAD1224324_M
          </div>
        </div>
      </div>
    </template>
    <div class="container space-y-8 !max-w-3xl py-8">
      <h1 class="text-4xl font-bold">
        Navigating Senior Eligibility for the Medicare Part B Giveback
      </h1>
      <p class="text-lg">
        Reducing healthcare costs is a priority for many seniors, and one
        valuable benefit that can help is the Medicare Part B Giveback. This
        guide explains how eligible individuals can take advantage of this
        program and lower their Medicare expenses.
      </p>

      <h3 class="text-2xl font-bold"
        >Understanding the Medicare Part B Giveback</h3
      >
      <p class="text-lg">
        Medicare, the U.S. federal health insurance program, primarily serves
        individuals aged 65 and older, along with some younger individuals with
        qualifying disabilities or severe health conditions. While Original
        Medicare requires enrollees to pay a standard Part B premium, certain
        Medicare Advantage Plans offer a Part B Giveback, which can help reduce
        these monthly costs.
      </p>

      <h3 class="text-2xl font-bold">What is the Medicare Part B Giveback?</h3>
      <p class="text-lg">
        The Medicare Part B Giveback, also known as the Part B premium
        reduction, is a benefit offered by some Medicare Advantage (Medicare
        Part C) plans. These plans, provided by private insurers approved by
        Medicare, may refund a portion of the enrollee's Part B premium directly
        through their Social Security check. This benefit helps lower
        out-of-pocket costs without reducing medical coverage.
      </p>

      <h3 class="text-2xl font-bold">How the Part B Giveback Works</h3>
      <p class="text-lg">
        Seniors who enroll in a Medicare Advantage Plan with the Part B Giveback
        continue receiving all the benefits of Medicare Parts A and B, along
        with additional perks like dental, vision, and prescription drug
        coverage. The key difference is that the insurance provider covers part
        of the Medicare Part B premium, reducing what the enrollee has to pay.
        The amount of the giveback varies depending on the plan and the insurer.
      </p>

      <h3 class="text-2xl font-bold">Key Criteria for Eligibility</h3>
      <ol class="list-decimal pl-8 text-lg space-y-2">
        <li>
          <strong
            >Enrollment in a Medicare Advantage Plan with the Giveback
            Benefit</strong
          ><br />
          To qualify for the Part B Giveback, a senior must be enrolled in a
          Medicare Advantage Plan that specifically includes this feature. Not
          all plans offer this benefit, so it's important to compare options
          carefully.
        </li>
        <li>
          <strong>Responsibility for Paying the Part B Premium</strong><br />
          Individuals who receive full financial assistance from Medicaid or
          other programs that already cover their Part B premium may not be
          eligible for the giveback. This benefit is designed for those who are
          responsible for paying their Part B premium out-of-pocket.
        </li>
        <li>
          <strong>Living in a Plan's Service Area</strong><br />
          Medicare Advantage Plans with the Part B Giveback are only available
          in certain regions. Seniors must reside in an area where a
          participating plan is offered to be eligible for the premium
          reduction.
        </li>
      </ol>

      <h3 class="text-2xl font-bold">Maximizing Your Savings</h3>
      <ul class="list-disc pl-8 text-lg space-y-2">
        <li>
          <strong>Compare Plan Options Annually</strong><br />
          Medicare Advantage Plans, including those offering the Part B
          Giveback, can change each year. Reviewing available plans during the
          Medicare Annual Enrollment Period ensures seniors select the best
          coverage with the highest savings potential.
        </li>
        <li>
          <strong>Consult with Medicare Experts</strong><br />
          Speaking with a Medicare specialist can help clarify which plans offer
          the giveback benefit and determine the most cost-effective option
          based on individual healthcare needs.
        </li>
        <li>
          <strong>Verify the Giveback Amount</strong><br />
          The amount of the Part B Giveback varies by plan and location.
          Checking with the insurance provider will help confirm how much of the
          premium will be refunded and how it will be applied.
        </li>
      </ul>

      <p class="text-lg">
        For seniors looking to reduce their Medicare costs, the Part B Giveback
        is a valuable benefit that can lower monthly expenses while maintaining
        full coverage. By understanding eligibility requirements and comparing
        plan options, seniors can take advantage of this savings opportunity and
        better manage their healthcare costs.
      </p>

      <Button variant="undefined" :to="destination" class="border-0">
        <img
          src="../../assets/simple-shop-plans.png"
          width="614"
          height="300"
          alt="An ad about Medicare Advantage plans"
        />
      </Button>

      <hr class="border-zinc-300" />
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}

.custom-transition {
  transition: width 0.5s;
}

.animate-custom-ping {
  animation: custom-ping 1.2s infinite;
}

@keyframes custom-ping {
  0% {
    box-shadow: 0 0 0 0 rgba(34, 197, 94, 1);
  }

  50% {
    box-shadow: 0 0 0 14px rgba(18, 59, 34, 0.7);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(11, 63, 30, 0);
  }
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "robots": "noindex"
  }
}
</route>
