<script setup lang="ts">
import { bypassFilter } from '@vueuse/shared'

const props = withDefaults(
  defineProps<{
    placementId: string
    afid: string
    label: string
    variant?: 'red' | 'yellow'
    done?: boolean
  }>(),
  {
    variant: 'red',
    done: true,
  }
)

type CallOption = {
  id: string
  uri: string
  name: string
}

const loading = ref(true)
const error = ref(false)
const callOption = ref<CallOption | null>(null)
const lastActive = ref(0)
const focused = useWindowFocus()
const phoneNumber = ref('')

const load = async () => {
  loading.value = true
  error.value = false
  try {
    const data = await getCustomTransferPhoneNumber(
      props.afid,
      props.placementId
    )

    phoneNumber.value = phone.uri

    if (data) {
      phoneNumber.value = `tel:+${data.value}`
    }
  } catch (e) {
    console.error('Error on retrieving new number')
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await load()
  const idle = useIdle(60000, { eventFilter: bypassFilter })

  watchSyncEffect(() => {
    lastActive.value = idle.lastActive.value
  })
})

const handleCtcClick = async (event: Event) => {
  const country = ip.lookup?.countryCode?.toUpperCase()
  if (country && country !== 'US') {
    event.preventDefault()
    return
  }

  const product = dataLayer.get('product') || null
  const didNumber = phone.value

  analytics.track('ctcClicked', {
    product,
    didNumber,
  })

  const clickTimestamp = lastActive.value

  await until(focused).toBe(false)

  if (clickTimestamp === lastActive.value) {
    const callStartDate = Date.now()

    await until(focused).toBe(true)

    const callEndDate = Date.now()
    const callDurationMs = callEndDate - callStartDate

    analytics.track('ctcReturned', {
      product,
      didNumber,
      callDurationMs,
    })
  }
}
</script>

<template>
  <div class="rounded-md shadow">
    <div v-if="loading" class="p-8">
      <Spinner class="mx-auto" />
    </div>

    <div v-else-if="error" class="p-8 text-center">
      An error occurred, please
      <button class="text-blue-900 underline" @click="load">try again</button>.
    </div>

    <div v-else class="divide-y divide-gray-100">
      <Button
        wide
        size="lg"
        :variant="'red'"
        :to="phoneNumber"
        @click="handleCtcClick"
        class="!rounded-full !text-sm !px-4 !py-2"
      >
        <span class="relative block w-6 h-6 mr-6">
          <span class="absolute inset-0 flex items-center justify-center">
            <span :class="['bg-white/30', 'animate-ping w-6 h-6 rounded-full']">
            </span>
          </span>
          <span class="absolute inset-0 flex items-center justify-center">
            <Icon
              i-heroicons-solid:phone
              :class="['text-white', 'w-6 h-6 animate-shake']"
            />
          </span>
        </span>

        <span>
          <slot name="text">
            {{ label }}
          </slot>
        </span>
      </Button>
    </div>
  </div>
</template>
